import { Box, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import Img from "gatsby-image"
import React from "react"
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox"
import "./pictures.css"

const useStyles = makeStyles(theme => ({
  "&#SRLLightbox": {
    maxHeight: "initial !important",
    border: "3px solid red",
  },
  imageContainer: {
    cursor: "pointer",
    filter: "brightness(0.9)",
    transition: "filter 300ms ease-in-out",
    width: 0,
    height: 0,
    marginBottom: "64px",
    "&:hover": {
      filter: "brightness(1)",
    },
    "&#lightbox_picture0": {
      width: "25%",
      height: "180px",
      border: "1px solid #111",
      [theme.breakpoints.down("sm")]: {
        height: "120px",
      },
      [theme.breakpoints.down("xs")]: {
        width: "33.3%",
        height: "120px",
      },
    },
    "&#lightbox_picture1": {
      width: "25%",
      height: "180px",
      border: "1px solid #111",
      [theme.breakpoints.down("sm")]: {
        height: "120px",
      },
      [theme.breakpoints.down("xs")]: {
        width: "33.3%",
        height: "120px",
      },
    },
    "&#lightbox_picture2": {
      width: "25%",
      height: "180px",
      border: "1px solid #111",
      [theme.breakpoints.down("sm")]: {
        height: "120px",
      },
      [theme.breakpoints.down("xs")]: {
        width: "33.3%",
        height: "120px",
      },
    },
    "&#lightbox_picture3": {
      width: "25%",
      height: "180px",
      border: "1px solid #111",
      [theme.breakpoints.down("sm")]: {
        width: 0,
        height: 0,
      },
      [theme.breakpoints.down("xs")]: {
        width: 0,
        height: 0,
      },
    },
  },
  galleryContainer: {
    display: "flex",
    marginTop: theme.spacing(2),
  },
}))

const options = {
  buttons: {
    showDownloadButton: false,
    showAutoplayButton: false,
    showFullscreenButton: false,
    showThumbnailsButton: false,
    backgroundColor: "rgba(0,0,0,0.5)",
    iconColor: "#F26522",
  },
  caption: {
    showCaption: false,
  },
  thumbnails: {
    thumbnailsSize: ["180px", "120px"],
  },
  settings: {
    disablePanzoom: true,
  },
}

export const PicturesWithLightBox = ({ pictures }) => {
  const classes = useStyles()
  return (
    <>
      <Typography variant="h3">Pictures</Typography>
      <SimpleReactLightbox>
        <SRLWrapper options={options}>
          <Box className={classes.galleryContainer}>
            {pictures.map((picture, index) => (
              <Box
                mr={0}
                className={classes.imageContainer}
                id={"lightbox_picture" + index}
              >
                <Img
                  fluid={picture.imageFile.childImageSharp.fluid}
                  alt={picture.url}
                  style={{
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
              </Box>
            ))}
          </Box>
        </SRLWrapper>
      </SimpleReactLightbox>
    </>
  )
}
